import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";
import fastStart from "../../images/fast-start-bonuses-icon.svg";

const StyledDiv = styled.div`
	padding: 100px 0;

	.heading img {
		padding-right: 20px;
		width: 2%;
	}

	.fast-start-bonuses {
		display: flex;
		align-items: center;
		justify-content: space-around;
		font: normal normal normal 24px/31px Nexa-light;

		.bonus {
			padding: 50px 25px;
			border: 1px solid #ffff;

			&:hover {
				border: 1px solid #44b2dd;
				border-radius: 8px;
			}
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		padding: 20px 0;

		.heading img {
			padding-right: 20px;
			width: 5%;
		}

		.fast-start-bonuses {
			flex-direction: column;

			.bonus {
				padding: 30px 20px;
				border: 1px solid #ffff;

				&:hover {
					border: none;
					border-radius: 0px;
				}
			}
		}
	}
`;

const FastStartBonusSection = () => {
	return (
		<StyledDiv className="fast-start-bonus-section">
			<div data-aos="fade-down">
				<div className="heading">
					<img src={fastStart} alt="" />
					Fast Start Bonus
				</div>
				<p>
					Active members are eligible to receive
					one-time fast start bonuses on new
					enrollment and upgrade orders from their
					personally enrolled members.
				</p>
			</div>
			<div className="fast-start-bonuses">
				<div
					className="bonus"
					data-aos="fade-left"
					data-aos-once={false}>
					<b>Choice / $99.99</b>
					<br />
					$25 bonus/50 PV
				</div>
				<div
					className="bonus"
					data-aos="fade-left"
					data-aos-once={false}
					data-aos-delay={200}>
					<b>Select / $299.99</b>
					<br />
					$50 bonus/150 PV
				</div>
				<div
					className="bonus"
					data-aos="fade-left"
					data-aos-once={false}
					data-aos-delay={400}>
					<b>Premium / $599.99</b>
					<br />
					$100 bonus/300 PV
				</div>
				<div
					className="bonus"
					data-aos="fade-left"
					data-aos-once={false}
					data-aos-delay={600}>
					<span className="elite">
						<b>Elite / $1499.99</b>
						<br />
						$200 bonus/1000 PV
					</span>
				</div>
			</div>
		</StyledDiv>
	);
};

export default FastStartBonusSection;
