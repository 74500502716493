import React from "react";
import styled from "styled-components";
import TopBanner from "../../Sections/TopBanner/TopBanner";
import newBanner from "../../images/compressedLeader.jpg";
import mobileBanner from "../../images/mobileLeadershipPcompressed.png";
import PersonCard from "./PersonCard";
import { breakpoints } from "../../utils/breakpoints";
import topText1 from "../../images/Text_Background_for_all_pages.png";
import topText2 from "../../images/Text_Background_for_all_pages_2.png";
import topText3 from "../../images/Text_Background_for_all_pages_3.png";


// images
import austin from "../../images/codeAustin.png";
import cade from "../../images/codeCade.png";
import chad from "../../images/codeChad.png";
import christian from "../../images/newChristianHeadshot.png";
import jamie from "../../images/codeJamie.png";
import kevin from "../../images/codeKevin.png";
import riker from "../../images/codeRiker.png";
import laura from "../../images/codeLaura.png";
import stefan from "../../images/stefan_leadership.png";


const StyledDiv = styled.div`
	
	background: #ffffff 0% 0% no-repeat padding-box;

	.content-container {
		padding: 10px 0;

		.reverse {
			flex-direction: row-reverse;
		}
	}

	.topTextBottom {
		width: 100%;
		height: 32vh;
		margin-top: -5px;
		position: relative;

		.sub-text {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
			width: 95%;
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		.content-container {
			padding: 30px 0;
			width: 90%;

			.reverse {
				flex-direction: column;
			}
		}
	}
`;

const Leadership = () => {
	// 0 - left
	// 1 - right
	var imgSide = 1;

	return (
		<StyledDiv>
			<TopBanner
				bg={
					window.innerWidth < 750
						? mobileBanner
						: newBanner
				}
				txt={
					<>
						<b>Our mission</b> is to help
						people live brilliant lives.
					</>
				}
			/>
			<div className="content-container">
				{employees.map((employee) => {
					imgSide === 1
						? (imgSide = 0)
						: (imgSide = 1);

					return (
						<PersonCard
							key={employee.name}
							data={employee}
							side={imgSide}
						/>
					);
				})}
			</div>
			<div className="topTextBottom">
				<p className="sub-text">
					{
						"We are committed to continually provide our members with smart leading edge opportunities, resources, tools, and education to help amplify quality of life."
					}
				</p>
				<img
					src={
						window.screen.width > 1000
							? topText1
							: window.screen.width >
							  500
							? topText2
							: topText3
					}
					alt="topText"
					className="topTextBottom"
				/>
			</div>
		</StyledDiv>
	);
};

const employees = [
	{
		name: "CHAD GARNER",
		title: "President",
		desc: "Chad brings over 18 years of sales, operations, and marketing experience. Chad was a founding member of the executive team for Daily Bread, a successful direct sales company that was sold to a private equity firm. He has built and scaled multiple high impact sales organizations throughout his career. Chad led the Inside Sales, Outside Sales, Affiliate Sales, Business Development, and Customer Success departments while helping raise venture capital for a technology startup called PeopleKeep. His passion is helping others improve their financial situation so they can live stress-free and enjoy life.",
		image: chad,
	},
	{
		name: "CHRISTIAN CRABTREE",
		title: "Senior Director of Sales",
		desc: "Christian lives by the saying “It's never too late to become what you might have been”. With a background in psychology and his MBA in Change Management, Christian understands the planning, development and execution that is required to help others succeed. He brings 16 years of management, direct sales, operations and corporate training experience. As a former collegiate athlete and coach, his passion is to mentor those he comes in contact with, lead by example, and create habits that result in a win. Christian’s main focus is to ensure that each individual in the business has the tools, support and training needed to succeed.",
		image: christian,
	},
	{
		name: "JAMIE BROWN",
		title: "Director of Marketing Communications",
		desc: "Jamie received her Bachelor's degree from Utah Valley University. Throughout her education she learned strong communications skills and knows how to successfully communicate with individuals and large groups. Jamie is a former NCAA Division 1 collegiate athlete. Her athletic career taught her to work hard both on and off the field, and she prides herself on being a member of a team. Jamie believes communication is the key to success in all aspects of life and is passionate to help others by providing them with the tools, marketing, and information needed to achieve their goals.",
		image: jamie,
	},
	{
		name: "AUSTIN SMITH",
		title: "Director of Product & Technology",
		desc: "Austin Smith earned a Bachelors in Business Management and Minor in Finance at Utah Valley University. He also completed several software development programs and has experience in a variety of coding languages like SQL, Python, JAVA, PHP, Javascript, and C#. Austin previously worked in construction management and oversaw multiple aspects of project management with Ovation Homes. Austin has held a variety of positions from Customer Support to Product Specialist and now is the Director of Product. Austin’s passion is to maximize product value so customers and distributors will continually use and share the products with others.",
		image: austin,
	},
	{
		name: "Stefan Kassing",
		title: "Director of Global Education",
		desc: "Stefan is a passionate learner and innovator who constantly looks for new ways to improve and promote learning. He has been working in the trading and education sector since 2014. He is responsible for developing and implementing the global education strategy for iGenius. He leads a team of education experts who create innovative and high-quality learning content and experiences for iGenius customers. He works closely with the other departments of iGenius to ensure that the education offerings are aligned with the needs and goals of the target audiences. Stefan is also involved in designing and delivering internal training and development programs for iGenius’ educators.",
		image: stefan,
	},
	{
		name: "RIKER HEALY",
		title: "Director of Customer Support",
		desc: "Riker is someone who believes that success is always giving 100%. Fully dedicating himself to the task at hand has helped Riker find great success in the many roles he has played throughout his career. Riker has an Accounting degree and background in business management. He believes that by utilizing the unique strengths of each member on his team, he can accomplish any task that is handed to him. Riker loves tackling new problems and the learning that comes in the pursuit of finding efficient solutions. Solving problems, and learning along the way, is Riker’s driving force that motivates him while working directly with our customers.",
		image: riker,
	},
	{
		name: "CADE MILLER",
		title: "Sales Operations Manager",
		desc: "Cade prides himself on applying a non-conformist approach both in the corporate world and outside of the office. He earned business degrees from Utah Valley University and The University of Utah. Cade continues to seek learning opportunities as often as possible. Challenges are what motivate Cade. Over the better part of the last decade, he has helped the Investview group of companies grow from the ground up. Cade is a strong believer in our company’s mission and continues to spend significant time becoming proficient in the financial markets. Cade uses his market education to help individuals achieve their financial objectives.",
		image: cade,
	},
	{
		name: "LAURA HANCOCK",
		title: "Graphic Designer",
		desc: "Laura Hancock started her experience within the creative industry through her passion for photography. Soon after, she found a passion for graphic design as she was earning her Bachelor’s degree in Visual Communication from Brigham Young University – Idaho. She started her first job in the creative industry as a graphic design intern in 2017 and has been working in the field ever since. Laura has continued developing her eye for modern art and design as she has been on many design teams and applies her experience to the iGenius brand.",
		image: laura,
	},
];

export default Leadership;
