import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import DropDown from "./DropDown";
import { HiMenu } from "react-icons/hi";
import { IconContext } from "react-icons";
import { breakpoints } from "../../utils/breakpoints";

const StyledNav = styled.div`
	width: 85%;
	font: normal normal normal 1vw/1vw Nexa-bold;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.signinbtn {
		width: 10vw;
		height: 3vw;
		cursor: pointer;
		border: none;
		outline: none;
		background: #44b2dd 0% 0% no-repeat padding-box;
		border-radius: 4px;
		font: normal normal normal 1vw/1vw Nexa-bold;
		color: #ffffff;
	}

	.signinbtn:hover {
		background: #414141 0% 0% no-repeat padding-box;
	}

	.navigation-menu {
		margin-left: auto;
	}

	.navigation-menu ul {
		display: flex;
		align-items: center;
		padding: 0;
		margin: 0;
	}

	.navigation-menu li {
		list-style-type: none;
		margin: 0 0.75rem;
	}

	.navigation-menu li a {
		text-decoration: none;
		display: block;
		width: 100%;
		text-align: center;
		color: #101010;

		&:hover {
			color: #44b2dd;
		}
	}

	.hamburger {
		border: 0;
		height: 55px;
		width: 55px;
		padding: 0.5rem;
		border-radius: 50%;
		background-color: #44b2dd;
		cursor: pointer;
		transition: background-color 0.2s ease-in-out;
		display: none;

		&:hover {
			background-color: #44b2dd;
		}
	}

	@media screen and ${breakpoints.device.laptop} {
		.hamburger {
			display: none;
		}
	}

	@media screen and ${breakpoints.device.tablet} {
		width: auto;
		font-size: 3vw;

		.signinbtn {
			font-size: 3vw;
			width: 15vw;
			height: 4vw;
		}

		.hamburger {
			display: block;
		}

		.navigation-menu ul {
			flex-direction: column;
			position: absolute;
			top: 70px;
			left: 0;
			width: 100%;
			height: auto;
			background: #fcfcfc 0% 0% no-repeat padding-box;
			box-shadow: 0px 3px 16px #3d3d3d14;
			z-index: 2;
			display: none;
		}

		.navigation-menu li {
			text-align: center;
			margin: 0;
		}

		.navigation-menu li a {
			color: black;
			width: 100%;
			padding: 1.5rem 0;
		}

		.navigation-menu li:hover {
			background-color: #eee;
		}

		.navigation-menu.expanded ul {
			display: block;
			margin-top: -6px;
			z-index: 101;
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		font-size: 4vw;

		.signinbtn {
			font-size: 4vw;
			width: 45vw;
			height: 10vw;
		}

		.hamburger {
			svg {
				height: 2.75em;
				width: 2.3em;
			}
		}
	}
`;

const Navigation = ({ setIsSubModalOpen }) => {
	const [isNavExpanded, setIsNavExpanded] = useState(false);
	const isReplicated = window.replicated;

	window.onload = () => setIsNavExpanded(false);
	
		var url_string = window.location.href;
		var url = new URL(url_string);
		var p = url.searchParams.get("enrolling");
		console.log(p);
		if(p === "true"){
			setIsSubModalOpen(true);
		}
	
	

	return (
		<StyledNav>
			<button
				className="hamburger"
				onClick={() => {
					setIsNavExpanded(!isNavExpanded);
				}}>
				<IconContext.Provider
					value={{
						color: "white",
						height: "2em",
					}}>
					<HiMenu />
				</IconContext.Provider>
			</button>
			<div
				className={
					isNavExpanded
						? "navigation-menu expanded"
						: "navigation-menu"
				}>
				<ul>
					<li
						onClick={() => {
							setIsNavExpanded(
								!isNavExpanded
							);
						}}>
						<Link to="/">Home</Link>
					</li>
					<li
						onClick={() => {
							setIsNavExpanded(
								!isNavExpanded
							);
						}}>
						<Link to="/products">
							Products
						</Link>
					</li>
					<li
						onClick={() => {
							setIsNavExpanded(
								!isNavExpanded
							);
						}}>
						<Link to="/opportunity">
							Opportunity
						</Link>
					</li>
					<li
						onClick={() => {
							setIsNavExpanded(
								!isNavExpanded
							);
						}}>
						<Link to="/memberbenefits">
							Member Benefits
						</Link>
					</li>
					<li
						onClick={() => {
							setIsNavExpanded(
								!isNavExpanded
							);
						}}>
						<Link to="/events">Events</Link>
					</li>
					<li
						onClick={() => {
							setIsNavExpanded(
								!isNavExpanded
							);
						}}>
						<Link to="/successstories">
							Success Stories
						</Link>
					</li>
					<li>
						<DropDown
							closeNav={
								setIsNavExpanded
							}
							checkNav={isNavExpanded}
						/>
					</li>
					<li
						style={{
							display: isReplicated
								? "block"
								: "none",
						}}>
						<a href="#home">
							<button
								onClick={() => {
									console.log(
										"KILROY WAS HERE?",
										setIsSubModalOpen
									);
									setIsSubModalOpen(
										true
									);
								}}
								className="signinbtn">
								Get Started
							</button>
						</a>
					</li>
					<li>
						<a href="https://shield.igenius.biz/login.html">
							<button className="signinbtn">
								Sign In
							</button>
						</a>
					</li>
				</ul>
			</div>
		</StyledNav>
	);
};

export default Navigation;
