import React from "react";
import styled from "styled-components";
import TopBannerVideo from "../../Sections/TopBanner/TopBannerVideo";
import bbb from "../../images/bbb-rating.png";
import { breakpoints } from "../../utils/breakpoints";
import topText1 from "../../images/Text_Background_for_all_pages.png";
import topText2 from "../../images/Text_Background_for_all_pages_2.png";
import topText3 from "../../images/Text_Background_for_all_pages_3.png";

// banner video
import investviewBanner from "../../videos/SmallerInvestview.mp4";

const StyledDiv = styled.div`
overflow-x: hidden;
	background: #ffffff 0% 0% no-repeat padding-box;

	.topTextBottom {
		width: 100%;
		height: 32vh;
		margin-top: -5px;
		position: relative;

		.sub-text {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
			width: 95%;
		}

		.addBspace{
			margin-top: 20px;
		}
	}

	.content-container {
		padding: 100px 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 100px;
		align-items: center;
		justify-items: center;
		text-align: left;

		p {
			font-style: normal;
			font-size: 1.35vw;
			line-height: 2;
			width: 80%;
			padding: 25px 0;
			margin: 0;
		}

		img {
			width: 30vw;
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		.content-container {
			padding: 30px 0;
			grid-template-columns: 1fr;
			width: 90%;
			text-align: center;
			grid-gap: 20px;

			.heading {
				margin-bottom: 12px;
			}

			p {
				font-size: 4.5vw;
				margin: auto;
				line-height: 1.8;
				padding: 15px 0;
			}

			img {
				width: 70vw;
			}
		}
	}
`;

const Investview = () => {
	return (
		<StyledDiv>
			<TopBannerVideo
				isInvestview={true}
				video={investviewBanner}
				txt={
					<>
						iGenius is a subsidiary of a
						publicly traded company called
						Investview.
					</>
				}
			/>
			<div className="content-container">
				<div className="txt-section">
					<div className="heading">
						How We Work
					</div>
					
					<p>
						As a publicly traded company, we
						work closely with our legal
						teams to ensure that we are
						operating in compliance with
						government regulations.
						Investview is an emerging leader
						in the financial technology
						(FINTECH) sector.
					</p>
					<p>
						Investview (OTCQB:INVU) delivers
						an ecosystem of leading edge
						financial technologies, tools,
						research, and services that
						drive innovation in blockchain,
						AI, DeFi, and global finance.
					</p>
				</div>
				<div className="image-section">
					<img src={bbb} alt="" />
				</div>
			</div>

			<div className="topTextBottom">
				<p className="sub-text">
					{
						"Investview Inc. is a public company that trades on the OTCQB with the symbol INVU."
					}
					<br />

					{
						<>
							<a
								href="https://investview.com/"
								target="_blank"
								rel="noreferrer">
								
								<button className="section-btn addBspace">
									Visit
									investview.com
								</button>
							</a>
						</>
					}
				</p>
				<img
					src={
						window.screen.width > 1000
							? topText1
							: window.screen.width >
							  500
							? topText2
							: topText3
					}
					alt="topText"
					className="topTextBottom"
				/>
			</div>
			<br />
			<br />
		</StyledDiv>
	);
};

export default Investview;
