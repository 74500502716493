import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";
import topText1 from "../../images/Text_Background_for_all_pages.png";
import topText2 from "../../images/Text_Background_for_all_pages_2.png";
import topText3 from "../../images/Text_Background_for_all_pages_3.png";

const StyledDiv = styled.div`
	position: relative;

	.topBanner {
		width: 100%;
		height: 63vh;
	}

	.topText {
		width: 100%;
		height: 32vh;
		margin-top: -5px;
		position: relative;

		.sub-text {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
			width: 95%;
		}
	}

	@media screen and ${breakpoints.device.laptop} {

		.topBanner {
			
			height: 20vh;
		}
	
		.topText {			
			height: 20vh;	
	
			
		}

	}

	@media screen and ${breakpoints.device.mobileL} {
		min-height: 30vh;

	
		.topText {
			.sub-text {
				font-size: 4vw;
				padding: 25px 0;
				line-height: 7vw;
			}
		}

		.section-btn {
			font-size: 4vw;
		}
	}
`;

const TopBanner = ({ bg, txt, logo, btn, video }) => {
	return (
		<StyledDiv className="banner">
			{video ? (
				<video
					autoPlay
					muted
					loop
					className="videoEvent"
					style={{ width: "100%" }}>
					<source src={video} type="video/mp4" />
				</video>
			) : (
				<img
					src={bg}
					alt="topBanner"
					className="topBanner"
					loading="lazy"
				/>
			)}
			<div className="topText">
				<p className="sub-text">
					{txt}
					<br />
					{btn}
				</p>
				<img
					src={
						window.screen.width > 1000
							? topText1
							: window.screen.width >
							  500
							? topText2
							: topText3
					}
					alt="topText"
					className="topText"
				/>
			</div>
		</StyledDiv>
	);
};

export default TopBanner;
