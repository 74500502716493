import React from "react";
import styled from "styled-components";
import Benefit from "./Benefit";
import { breakpoints } from "../../utils/breakpoints";

// images/assets
import topUpIcon from "../../images/Top_Up_Logo.png";
import generationalIcon from "../../images/generational-match-icon.svg";
import ndauIcon from "../../images/ndau-bonuses-icon.svg";
import binaryTeam from "../../images/binary-team-commissions-icon.svg";
import fastStart from "../../images/fast-start-bonuses-icon.svg";
import leaderboard from "../../images/leaderboard-bonuses-icon.svg";
import millionDollar from "../../images/million-dollar-bonus-icon.svg";
import ndauPool from "../../images/smartFinanceTopLogo.png";
import rapidFire from "../../images/rapid-fire-bonuses-icon.svg";
import x6x12 from "../../images/x6x12-bonuses-icon.svg";

const StyledDiv = styled.div`
	padding-bottom: 100px;

	.benefits-container {
		display: grid;
		grid-template-columns: repeat(10, 1fr);


	}

	@media screen and ${breakpoints.device.mobileL} {
		.benefits-container {
			grid-template-columns: 1fr 1fr;
			row-gap: 50px;
			width: 80%;
			margin: auto;
		}

		padding-bottom: 20px;
	}
`;

const BenefitsSection = () => {
	return (
		<StyledDiv className="benefits-section">
			<div data-aos="fade-down">
				<div className="heading">Benefits</div>
				<p>
					iGenius members are rewarded for
					attracting and retaining new members.
				</p>
				<br />
			</div>
			<div className="benefits-container">
				{benefits.map(({ icon, txt }, idx) => (
					<Benefit
						key={idx}
						icon={icon}
						txt={txt}
					/>
				))}
			</div>
		</StyledDiv>
	);
};

const benefits = [
	{
		icon: <img src={fastStart} alt="" />,
		txt: (
			<>
				Fast Start
				<br />
				Bonuses
			</>
		),
	},
	{
		icon: <img src={rapidFire} alt="" />,
		txt: (
			<>
				Rapid Fire
				<br />
				Bonuses
			</>
		),
	},
	{
		icon: <img src={topUpIcon} alt="" />,
		txt: (
			<>
				Top Up
				<br />
				Bonuses
			</>
		),
	},
	{
		icon: <img src={x6x12} alt="" />,
		txt: (
			<>
				X6 & X12
				<br />
				Bonuses
			</>
		),
	},
	{
		icon: <img src={leaderboard} alt="" />,
		txt: (
			<>
				Leaderboard
				<br />
				Bonuses
			</>
		),
	},
	{
		icon: <img src={binaryTeam} alt="" />,
		txt: (
			<>
				Binary Team
				<br />
				Commissions
			</>
		),
	},
	{
		icon: <img src={millionDollar} alt="" />,
		txt: (
			<>
				Million Dollar
				<br />
				Bonus
			</>
		),
	},
	{
		icon: <img src={ndauPool} alt="" />,
		txt: (
			<>
				smartFINANCE
				<br />
				Bonus
			</>
		),
	},
	{
		icon: <img src={generationalIcon} alt="" />,
		txt: (
			<>
				Generational
				<br />
				Match
			</>
		),
	},
];

export default BenefitsSection;
