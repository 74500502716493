import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";
import oppImg from "../../webp/NewOppBannerWebPage.webp";
import newOppImg from "../../images/newOppPublic.png"
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const StyledDiv = styled.div`
	overflow-x: hidden;
	padding: 100px 0;
	background: linear-gradient(
		180deg,
		rgba(242, 242, 242, 1) 50%,
		rgba(255, 255, 255, 1) 100%
	);

	.content-container {
		display: grid;
		grid-template-columns: 50% 50%;
		align-items: center;

		.modal-btn {
			border: none;
			background-color: transparent;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			height: 100%;
			z-index: 1;
			cursor: pointer;
		}

		.left-side {
			text-align: left;

			.divider {
				margin-left: 0;
			}

			p {
				width: 90%;
				margin: 0;
				padding-bottom: 50px;
			}
		}

		.right-side {
			.opp-vid {
				position: relative;
				overflow: hidden;
				width: 100%;
				padding-top: 56.25%;

				iframe {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					width: 100%;
					height: 100%;
				}
			}

			.edu-vid {
				border-radius: 4px 4px 0px 0px;
				cursor: pointer;

				img {
					width: 100%;
				}
			}
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		padding: 50px 10px;

		.content-container {
			grid-template-columns: 1fr;
			row-gap: 13px;

			.left-side {
				text-align: center;

				.divider {
					margin: 25px auto;
				}

				p {
					margin: auto;
					padding-bottom: 10px;
				}
			}
		}
	}
`;

const OpportunitySection = ({ isModalOpen, setIsModalOpen, setVidCode }) => {
	return (
		<StyledDiv>
			{window.innerWidth > 750 ? (
				<div className="content-container">
					<motion.div
						initial={{ opacity: 0, x: -200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: 1,
						}}>
						<div className="left-side">
							<div className="heading">
								Opportunity
							</div>
							<div className="divider"></div>
							<p>
								<b>
									iGenius
									compensates
									members
								</b>{" "}
								for sharing our
								products rather
								than spend large
								amounts of money
								on advertising.
							</p>
							<Link to="/opportunity">
								<button className="section-btn">
									Learn
									More
								</button>
							</Link>
						</div>
					</motion.div>
					<motion.div
						initial={{ opacity: 0, x: 200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: 1,
						}}>
						<div className="right-side">
							<div className="edu-vid">
								<img
									onClick={() => {
										setIsModalOpen(
											!isModalOpen
										);
										setVidCode(
											"C9UjUciNPFQ"
										);
									}}
									src={
										newOppImg
									}
									alt=""
								/>
							</div>
						</div>
					</motion.div>
				</div>
			) : (
				<div className="content-container">
					<motion.div
						initial={{ opacity: 0, x: -200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: 1,
						}}>
						<div className="left-side">
							<div className="heading">
								Opportunity
							</div>
							<div className="divider"></div>
							<p>
								<b>
									iGenius
									compensates
									members
								</b>{" "}
								for sharing our
								products rather
								than spending
								large amounts of
								money on
								advertising.
							</p>
						</div>
					</motion.div>
					<motion.div
						initial={{ opacity: 0, x: 200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: 1,
						}}>
						<div className="right-side">
							<div className="edu-vid">
								<img
									onClick={() => {
										setIsModalOpen(
											!isModalOpen
										);
										setVidCode(
											"C9UjUciNPFQ"
										);
									}}
									src={
										newOppImg
									}
									alt=""
								/>
							</div>
						</div>
					</motion.div>
					<Link to="/opportunity">
						<button className="section-btn">
							Learn More
						</button>
					</Link>
				</div>
			)}
		</StyledDiv>
	);
};

export default OpportunitySection;
