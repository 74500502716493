import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";
import { motion } from "framer-motion";

const StyledDiv = styled.div`
	display: grid;
	width: 90%;
	margin: 0 auto 50px auto;
	align-items: end;

	.modal-btn {
		border: none;
		background-color: transparent;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		z-index: 1;
		cursor: pointer;
	}

	.sub-text {
		font-size: 1.5vw;
		width: 80%;
		margin: auto;
		padding: 50px;
		line-height: 2em;

		b {
			font-size: 1.75vw;
		}
	}

	.txt {
		grid-area: txt;
		width: auto;
	}

	.phone {
		position: relative;
		grid-area: phone;
		width: 100%;
		margin: 0 auto;

		img,
		.modal-btn {
			width: 80%;
			margin: 0 auto;
		}
	}

	.img {
		position: relative;
		grid-area: img;
		width: 100%;
		margin: auto;

		img {
			width: 100%;
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		margin: 0 auto 25px auto;
		.sub-text {
			font-size: 4.5vw;
			padding: 20px;

			b {
				font-size: 4.75vw;
			}
		}
	}
`;

const ProductSection = ({
	data,
	idx,
	isModalOpen,
	setIsModalOpen,
	setVidType,
	setVidCode,
}) => {
	return (
		<StyledDiv className={`${data.phoneSide}`} id={idx}>
			<p className="sub-text txt">{data.txt}</p>
			<motion.div
				className="phone"
				initial={{
					opacity: 0,
					x:
						data.phoneSide === "left"
							? "-50%"
							: "50%",
				}}
				whileInView={{ opacity: 1, x: 0 }}
				transition={{ duration: 1.25 }}>
				<a
					href={data.phoneVidCode}
					target="_blank"
					rel="noreferrer">
					<img src={data.phone} alt="" />
				</a>
			</motion.div>
			<div className="img">
				<button
					className="modal-btn"
					onClick={() => {
						setIsModalOpen(!isModalOpen);
						setVidType(data.thumbVidType);
						setVidCode(data.thumbVidCode);
					}}
				/>
				<img src={data.thumbnail} alt="" />
			</div>
		</StyledDiv>
	);
};

export default ProductSection;
