import React from "react";
import styled from "styled-components";
import { FaCaretDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import { breakpoints } from "../../utils/breakpoints";

const StyledDiv = styled.div`
	position: relative;
	padding: 1.5rem 10px;

	.dropdown-title {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.dropdown-content {
		display: none;
		position: absolute;
		top: 50%;
		left: 30%;
		transform: translate(-60%, 50%);
		background: #fcfcfc 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 16px #3d3d3d14;
		z-index: 1000;
		min-width: 600%;

		a {
			padding: 10px 0;
			min-width: 105px;
			width: 10vw;
		}
	}

	&:hover {
		.dropdown-content {
			display: flex;
			align-items: center;
			justify-content: space-evenly;
		}

		.caret {
			transition: all 0.3s ease;
			transform: rotateX(180deg);
		}
	}

	@media screen and ${breakpoints.device.tablet} {
		.dropdown-content {
			flex-direction: column;
			transform: translate(-50%, 10%);
			min-width: 100%;
			top: 50%;
			left: 50%;
		}
	}
`;

const DropDown = (props) => {
	return (
		<StyledDiv className="dropdown">
			<div className="dropdown-title">
				<div>Who We Are</div>
				<FaCaretDown className="caret" />
			</div>
			<div className="dropdown-content" id="dropDown">
				<Link to="/igeniuscares" onClick={() => { props.closeNav(!props.checkNav);}}>iGenius Cares</Link>
				<Link to="/investview" onClick={() => { props.closeNav(!props.checkNav);}}>Investview</Link>
				<Link to="/leadership" onClick={() => { props.closeNav(!props.checkNav);}}>Leadership</Link>
				<Link to="/policies" onClick={() => { props.closeNav(!props.checkNav);}}>Policies</Link>
				<Link to="/contactus" onClick={() => { props.closeNav(!props.checkNav);}}>Contact Us</Link>
			</div>
		</StyledDiv>
	);
};

export default DropDown;
