import React, { useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { breakpoints } from "../../utils/breakpoints";

const StyledDiv = styled.div`
	img {
		width: 15vw;
	}

	.activeSlide {
		transform: scale(1.1);
		transition: transform 300ms;
	}

	.slide {
		transform: scale(0.70);
		transition: transform 300ms;
		opacity: 0.5;
	}

	.slideTest {
		transform: scale(.87);
		transition: transform 300ms;
		opacity: 0.5;
	}

	.slideWrapper {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.prevArrow {
		position: absolute;
		top: 40%;
		left: 0px;
		z-index: 100;
		cursor: pointer;
		font-size: 2rem;
	}

	.nextArrow {
		position: absolute;
		top: 40%;
		right: 0px;
		z-index: 100;
		cursor: pointer;
		font-size: 2rem;
	}

	.slick-track{
		height: 60vh;
	}

	.slick-slider{
		width: 80%;
		margin: auto;
		margin-bottom: 35px;
	}

	.slick-slide{
		display: grid;
		align-items: center;
	}

	

	@media screen and ${breakpoints.device.mobileL} {
		.slick-slider{
			width: 95%;
			margin: auto;
			margin-bottom: 15px;
		}

		img {
			width: 30vw;
		}

		.slick-track{ 
			height: 30vh;
		}

		.prevArrow {
			position: absolute;
			top: 40%;
			left: 60px;
			z-index: 100;
			cursor: pointer;
			font-size: 2rem;
		}
	
		.nextArrow {
			position: absolute;
			top: 40%;
			right: 60px;
			z-index: 100;
			cursor: pointer;
			font-size: 2rem;
		}
	
		
	}
`;

const NextArrow = ({ onClick }) => {
	return (
		<div className="nextArrow" onClick={onClick}>
			<BsChevronRight />
		</div>
	);
};

const PrevArrow = ({ onClick }) => {
	return (
		<div className="prevArrow" onClick={onClick}>
			<BsChevronLeft />
		</div>
	);
};

const ProductCarousel = ({ images, slidesToShow = 3 }) => {
	const [imageIndex, setImageIndex] = useState(0);

	const settings = {
		centerMode: true,
		infinite: true,
		dots: false,
		autoplay: true,
		speed: 300,
		slidesToShow: slidesToShow,
		centerPadding: "0",
		swipeToSlide: true,
		focusOnSelect: true,
		pauseOnHover: false,
		adaptiveHeight: false,
		nextArrow: <NextArrow onClick />,
		prevArrow: <PrevArrow onClick />,
		beforeChange: (current, next) => setImageIndex(next),
		responsive: [
			{
				breakpoint: 1490,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 820,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const templateImages = images.map(({ image, link }, idx) => {		
		return (
			<div
				className={
					idx === imageIndex
						? "activeSlide"
						: idx === imageIndex - 1
						? "slideTest"
						: idx === imageIndex + 1
						? "slideTest"
						: idx === imageIndex - (images.length - 1)
						? "slideTest"
						: idx === imageIndex + (images.length - 1)
						? "slideTest"
						: "slide"
				}
				key={idx}>
				<div className="slideWrapper">
					<a href={link}>
						<img src={image} alt={idx} />
					</a>
				</div>
			</div>
		);
	});

	return (
		<StyledDiv>
			<Slider {...settings}>{templateImages}</Slider>		
		</StyledDiv>
	);
};

export default ProductCarousel;
