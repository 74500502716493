import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TopBanner from "../../Sections/TopBanner/TopBanner";
import newBanner from "../../images/compressedSucces.jpg";
import mobileBanner from "../../images/mobileFixSuccessP.png";
import StoryCard from "./StoryCard";
import { breakpoints } from "../../utils/breakpoints";
import Modal from "../../Sections/Modal/Modal";

// thumbnails
import sayna from "../../images/Testimonials-web thumbnails-Sayna.png";
import kristel from "../../images/Testimonials-web thumbnails-Kristel.png";
import anthony from "../../images/Testimonials-web thumbnails-Anthony.png";
import christian from "../../images/Testimonials-web thumbnails-Christian.png";
import miguel from "../../images/Testimonials-web thumbnails-Miguel.png";
import niamh from "../../images/Testimonials-web thumbnails-Niamh.png";
import jeremy from "../../images/Testimonials-web thumbnails-Jeremy.png";
import stephanie from "../../images/Testimonials-web thumbnails-Stephanie.png";
import oda from "../../images/successStoryOda.png";
import vald from "../../images/AlexaValdebenito.png";
import haider from "../../images/HaiderTestimonal.png";

const StyledDiv = styled.div`
	overflow-x: hidden;
	background: #ffffff 0% 0% no-repeat padding-box;

	.content-container {
		padding-bottom: 100px;

		p {
			padding: 50px 0;
		}

		.stories-container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 100px;
			padding: 50px 0;
			margin-bottom: 50px;
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		.content-container {
			width: 90%;
			padding-bottom: 20px;

			.stories-container {
				grid-template-columns: 1fr;
				padding: 10px 0;
				grid-gap: 40px;
			}

			.section-btn {
				font-size: 4.5vw;
			}
		}
	}
`;

const SuccessStories = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [vidCode, setVidCode] = useState(null);

	useEffect(() => {
		var modal = document.getElementById("modal");
		var video = document.getElementById("vimeo-iframe");

		if (isModalOpen) {
			modal.style.display = "block";
		} else {
			modal.style.display = "none";
			var temp = video.getAttribute("src")
			video.setAttribute("src", temp);
		}
	}, [isModalOpen]);

	return (
		<StyledDiv>
			<TopBanner
				bg={
					window.innerWidth < 750
						? mobileBanner
						: newBanner
				}
				txt={
					<>
						<b>
							iGenius is impacting
							lives
						</b>{" "}
						all around the world! Hear what
						some of our members have to say.
					</>
				}
			/>
			<br />
			<div className="content-container">
				<div className="stories-container">
					{Stories.map((story, idx) => (
						<StoryCard
							key={story.name}
							data={story}
							idx={idx}
							isModalOpen={
								isModalOpen
							}
							setIsModalOpen={
								setIsModalOpen
							}
							setVidCode={setVidCode}
						/>
					))}
				</div>
				<div id="modal" className="modal-container">
					<Modal
						vidType="youtube"
						vidCode={vidCode}
						setIsModalOpen={setIsModalOpen}
					/>
				</div>
			</div>
		</StyledDiv>
	);
};

const Stories = [
	{
		name: "Oda Abrahamsen",
		location: "Norway",
		story: '"Since I\'ve joined iGenius I\'ve had the most amazing personal growth. Once you learn the skill of trading, it\'s always with you. It\'s also powerful as a woman to say that I know how to trade, invest, and build a portfolio. It\'s truly empowering."',
		vidID: "Uss-c02qnOM",
		img: oda,
	},
	{
		name: "Alexandra Valdebenito",
		location: "Sweden",
		story: '"This opportunity has completely changed my life and my perspective. I\'m so grateful to be a part of iGenius, it\'s the best place to be."',
		vidID: "skQ-QedhCpA",
		img: vald,
	},
	{
		name: "Sayna Alipanah",
		location: "Canada",
		story: '"MONEYpro is a hidden gem inside of iGenius! MONEYpro taught me how to track my spending, create an emergency fund, and pay off debts. Because of MONEYpro, I am much more financially literate as a whole."',
		vidID: "Uss-c02qnOM",
		img: sayna,
	},
	{
		name: "Kristel Gomez",
		location: "Mexico",
		story: '"I really wanted to learn how to manage, make, and save my money. I feel so grateful to be a part of this iGenius platform because it has absolutely changed my life in so many ways."',
		vidID: "JxJaWVen8bA",
		img: kristel,
	},
	{
		name: "Anthony Napolitano",
		location: "USA",
		story: '"My favorite thing about the platform is getting access to the third-party relationships that iGenius has created for their members. I truly love it because the average person gets access to these services just by being a part of iGenius."',
		vidID: "7ngufvkmkII",
		img: anthony,
	},
	{
		name: "Christian Perez",
		location: "Canada",
		story: '"Going through the MONEYpro videos, book, and resources taught me how to find financial leaks and plug them to make sure that I don\'t lose money. Because of iGenius, I now have the skills and tools to manage my money and investments over time."',
		vidID: "F8J7NHaZlHE",
		img: christian,
	},
	{
		name: "Miguel Vees",
		location: "Germany",
		story: '"It was important for me to find a company that reflected the same morals that I was brought up with...iGenius gave me the right information to change my situation."',
		vidID: "biQFsW4ytVs",
		img: miguel,
	},
	{
		name: "Christina Haider",
		location: "Austria",
		story: '"Before iGenius I had a really bad money mindset. I\'ve now learned about the financial markets and how I can let money work for me. Because of all of these changes I\'ve made, I\'ve now gained location and time freedom, something that I\'ve always wanted."',
		vidID: "dR2rF7NGNjE",
		img: haider,
	},
	{
		name: "Niamh Murphy",
		location: "Scotland",
		story: '"I joined iGenius not thinking much of it. I knew it was going to be good for me, but I didn\'t realize how good. It has changed so many aspects of my life."',
		vidID: "iO2m6jUsQ-0",
		img: niamh,
	},
	{
		name: "Jeremy Harding",
		location: "USA",
		story: '"We have a University where we can take the time to educate ourselves and gain a more in depth knowledge. If you think about investing, but don\'t know where to start, this is a great way to learn more."',
		vidID: "gOmTwaPDnlI",
		img: jeremy,
	},
	{
		name: "Stephanie Bonilla",
		location: "USA",
		story: '"I buckled down on the education, I took a leap of faith, I put my money into the platform of services, and I was able to make a sufficient amount of income to provide for my family."',
		vidID: "oj5eeh8cBos",
		img: stephanie,
	}
];

export default SuccessStories;
