import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";

const StyledDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: left;
	padding: 70px 0;

	.image-section {
		width: 25vw;
		height: 25vw;
		border-radius: 8px;

		img {
			width: 100%;
		}
	}

	.txt-section {
		width: 60%;

		.name {
			font: normal normal bold 48px/80px Nexa-bold;
		}

		.title {
			font: normal normal bold 24px/80px Nexa-bold;
		}

		.desc {
			font: normal normal normal 24px/40px Nexa-light;
		}
	}

	@media screen and ${breakpoints.device.laptopL} {
		padding: 20px 0;

		.txt-section {
			width: 100%;
			margin-left: 60px;

			.name,
			.title {
				font-size: 25px;
				text-align: center;
			}

			.name {
				font: normal normal bold 28px/50px Nexa-bold;
			}

			.title {
				font: normal normal bold 28px/50px Nexa-bold;
			}

			.desc {
				font: normal normal normal 20px/30px Nexa-light;
				font-size: 15px;
			}
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		flex-direction: column;
		padding: 20px 0;

		.image-section {
			width: 50vw;
			height: 50vw;
			margin-bottom: 20px;
		}

		.txt-section {
			width: 100%;
			margin-left: 0px;

			.name,
			.title,
			.desc {
				font-size: 25px;
				text-align: center;
			}

			.name {
				font: normal normal bold 28px/50px Nexa-bold;
			}

			.title {
				font: normal normal bold 22px/40px Nexa-light;
			}

			.desc {
				font: normal normal normal 20px/30px Nexa-light;
				font-size: 15px;
			}
		}
	}
`;

const PersonCard = ({ data, side }) => {
	// 0 - left
	// 1 - right
	return (
		<StyledDiv className={side === 1 ? "reverse" : ""}>
			<div data-aos="flip-left">
				<div className="image-section">
					<img src={data.image} alt="" />
				</div>
			</div>
			<div className="txt-section">
				<div className="name">{data.name}</div>
				<div className="title">{data.title}</div>
				<div className="desc">{data.desc}</div>
			</div>
		</StyledDiv>
	);
};

export default PersonCard;
