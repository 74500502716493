import React from "react";
import styled from "styled-components";
import bg from "../../images/policies-bg.png";
import Policy from "./Policy";
import { FaRegFilePdf } from "react-icons/fa";
import { FiLink2 } from "react-icons/fi";
import { breakpoints } from "../../utils/breakpoints";
import { Link, Outlet } from "react-router-dom";

const StyledDiv = styled.div`
	height: auto;
	background: #001313 0% 0% no-repeat padding-box;
	background-size: cover;
	background-image: linear-gradient(
			rgba(0, 19, 19, 0.8),
			rgba(0, 19, 19, 0.8)
		),
		url("${bg}");
	color: #ffffff;

	.content-container {
		padding: 100px 0;

		.policies-container {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			row-gap: 0px;
			align-items: center;
			width: 90%;
			margin: auto;
			padding: 20px 0;
		}

		.hovered {
			background: transparent
				linear-gradient(
					145deg,
					#44b2dd 0%,
					#84c2e1 100%
				)
				0% 0% no-repeat padding-box;
			border-radius: 50%;

			.icon {
				transform: scale(5);
				transition: transform 30ms;
			}

			.txt {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, 20%);
				font-size: 15px;
			}
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		.content-container {
			padding: 0;

			.heading {
				padding: 50px 0;
			}

			.policies-container {
				grid-template-columns: 1fr;
				row-gap: 0;
				justify-items: center;
				padding: 10px 0;
			}
		}
	}
`;

const Policies = () => {
	return (
		<>
			<StyledDiv>
				<div className="content-container">
					<div className="heading">Policies</div>
					<div className="policies-container">
						<Link
							to="compliance#policy-content"
							preventScrollReset={
								true
							}>
							<Policy
								id={1}
								txt={
									"Compliance & Regulatory"
								}
								icon={
									<FiLink2 />
								}
							/>
						</Link>
						<div></div>
						<a
							href="https://igeniusglobal.io/documents/policies/iGenius-Policies-Procedures.pdf"
							target="_blank"
							rel="noreferrer">
							<Policy
								id={2}
								txt={
									"Policies & Procedures"
								}
								icon={
									<FaRegFilePdf />
								}
							/>
						</a>
						<div></div>
						<Link
							to="distributoragreement#policy-content"
							preventScrollReset={
								true
							}>
							<Policy
								id={3}
								txt={
									"Distributor Agreement"
								}
								icon={
									<FiLink2 />
								}
							/>
						</Link>
						<div></div>
						<Link
							to="privacypolicy#policy-content"
							preventScrollReset={
								true
							}>
							<Policy
								id={4}
								txt={
									"Privacy Policy"
								}
								icon={
									<FiLink2 />
								}
							/>
						</Link>
						<div></div>
						<Link
							to="purchaseterms#policy-content"
							preventScrollReset={
								true
							}>
							<Policy
								id={5}
								txt={
									"Purchase Terms"
								}
								icon={
									<FiLink2 />
								}
							/>
						</Link>
						<div></div>
					</div>
				</div>
			</StyledDiv>
			<div id="policy-content">
				<Outlet />
			</div>
		</>
	);
};

export default Policies;
