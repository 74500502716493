import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";

const StyledDiv = styled.div`
	position: relative;
	height: 980px;
	background: transparent 0% 0% no-repeat padding-box;

	.content-container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 40%;
		margin: auto;
		border-radius: 8px;
		background: transparent 0% 0% no-repeat padding-box;
		background-image: linear-gradient(
			rgba(0, 0, 0, 0.4),
			rgba(0, 0, 0, 0.4)
		);
		backdrop-filter: blur(30px);
		-webkit-backdrop-filter: blur(30px);

		.logo {
			width: 10vw;
			height: 10vw;
			margin: auto;
			padding: 10px;

			img {
				width: 100%;
			}
		}

		form {
			width: 80%;
			margin: auto;

			input {
				width: 100%;
				height: 7vh;
				background: transparent 0% 0% no-repeat
					padding-box;
				border-radius: 8px;
				border: none;
				backdrop-filter: blur(30px);
				-webkit-backdrop-filter: blur(30px);
				margin: 25px 0;
				font: normal normal normal 16px/32px Nexa-light;
				color: #ffffff;
				padding-left: 10px;
			}

			.login-btn {
				background: #ffffffd1 0% 0% no-repeat
					padding-box;
				border: 2px solid #44b2dd;
				border-radius: 8px;
				font: normal normal normal 24px/32px Nexa-light;
				color: #44b2dd;
			}
		}

		.forget-pw {
			padding-top: 25px;
			padding-bottom: 50px;
			width: 80%;
			margin: auto;
			text-align: left;

			a {
				text-decoration: none;
				font: normal normal normal 24px/32px Nexa-light;
				color: #ffffff;
			}
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		.content-container {
			width: 90%;

			.logo {
				width: 15vw;
				height: 15vw;
				margin: auto;
			}
		}
	}
`;

const Login = () => {
	function sendOver() {
		window.location = "https://shield.igenius.biz/login.html";
	}

	sendOver();

	return <StyledDiv></StyledDiv>;
};

export default Login;
