import React, { useState, useEffect } from "react";

// sections
import HomeBanner from "./HomeBanner";
import EducationSection from "./EducationSection";
import ProductsSection from "./ProductsSection";
import EventsSection from "./EventsSection";
import StatsSection from "./StatsSection";
import SuccessStoriesSection from "./SuccessStoriesSection";
import OpportunitySection from "./OpportunitySection";
import IGeniusCaresSection from "./IGeniusCaresSection";
import Modal from "../../Sections/Modal/Modal";

const Home = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [vidCode, setVidCode] = useState("");

	useEffect(() => {
		var modal = document.getElementById("modal");
		var video = document.getElementById("vimeo-iframe");

		if (isModalOpen) {
			modal.style.display = "block";
		} else {
			modal.style.display = "none";
			var temp = video.getAttribute("src")
			video.setAttribute("src", temp);
		}
	}, [isModalOpen]);

	return (
		<div>
			<HomeBanner />
			<EducationSection
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				setVidCode={setVidCode}
			/>
			<ProductsSection />
			<EventsSection
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				setVidCode={setVidCode}
			/>
			<StatsSection />
			<SuccessStoriesSection
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				setVidCode={setVidCode}
			/>
			<OpportunitySection
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				setVidCode={setVidCode}
			/>
			<IGeniusCaresSection />
			<div id="modal" className="modal-container" style={{ display: 'none' }}>
				<Modal
					vidType="youtube"
					vidCode={vidCode}
					setIsModalOpen={setIsModalOpen}
				/>
			</div>
		</div>
	);
};

export default Home;
