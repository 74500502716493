import React, { useState } from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";
import globe from "../../utils/lf30_editor_pkdicvvj_globe.json";
import Lottie from "lottie-react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const StyledDiv = styled.div`
	overflow-x: hidden;
	overflow-y: hidden;
	padding: 100px 0;
	background: linear-gradient(
		180deg,
		rgba(242, 242, 242, 1) 50%,
		rgba(255, 255, 255, 1) 100%
	);

	.content-container {
		display: flex;
		align-items: center;
		justify-content: space-evenly;

		.stats {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 100px;
			align-items: center;

			.stat {
				.stat-txt {
					font: normal normal normal 2vw/2vw
						Nexa-light;
					color: #3d3d3d;
				}

				.stat-num {
					font: normal normal bold 2.5vw/2.5vw
						Nexa-bold;
					color: #3d3d3d;
					padding-top: 25px;
				}
			}
		}
	}

	@media screen and ${breakpoints.device.tablet} {
		padding: 10px 0;
		.content-container {
			flex-direction: column;

			.stats {
				flex-direction: column;

				.stat {
					padding: 10px 0;

					.stat-txt {
						font-size: 3vw;
					}

					.stat-num {
						font-size: 3.5vw;
					}
				}
			}
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		.content-container {
			.stats {
				grid-gap: 50px;

				.stat {
					.stat-txt {
						font-size: 5vw;
						line-height: 7vw;
					}

					.stat-num {
						font-size: 5.5vw;
					}
				}
			}
		}
	}
`;

const StatsSection = () => {
	const [counterOn, setCounterOn] = useState(false);

	return (
		<StyledDiv>
			<div className="content-container">
				<div data-aos="fade-up" data-aos-once={false}>
					<div className="graphic">
						<Lottie
							animationData={globe}
							background="transparent"
							speed="1"
							style={{
								width: "394px",
								height: "394px",
							}}
							loop
							autoplay></Lottie>
					</div>
				</div>
				<ScrollTrigger
					onEnter={() => setCounterOn(!counterOn)}
					onExit={() => setCounterOn(true)}>
					<div className="stats">
						<div className="stat">
							<div className="stat-txt">
								Countries
							</div>
							<div className="stat-num">
								{counterOn ? (
									<CountUp
										end={
											123
										}
										duration={
											2
										}
									/>
								) : (
									123
								)}
							</div>
						</div>
						<div className="stat">
							<div className="stat-txt">
								Products
							</div>
							<div className="stat-num">
								{counterOn ? (
									<CountUp
										end={
											22
										}
										duration={
											2
										}
									/>
								) : (
									22
								)}
								+
							</div>
						</div>
						<div className="stat">
							<div className="stat-txt">
								Educational
								Videos
							</div>
							<div className="stat-num">
								{counterOn ? (
									<CountUp
										end={
											2500
										}
										duration={
											2
										}
									/>
								) : (
									2500
								)}
								+
							</div>
						</div>
						<div className="stat">
							<div className="stat-txt">
								Weekly Live
								Sessions
							</div>
							<div className="stat-num">
								{counterOn ? (
									<CountUp
										end={
											85
										}
										duration={
											2
										}
									/>
								) : (
									85
								)}
								+
							</div>
						</div>
					</div>
				</ScrollTrigger>
			</div>
		</StyledDiv>
	);
};

export default StatsSection;
