import React from "react";
import styled from "styled-components";
import { MdLocationOn } from "react-icons/md";
import { breakpoints } from "../../utils/breakpoints";
import { motion } from "framer-motion";

const StyledDiv = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 16px #e9e9e9;
	border-radius: 4px;
	text-align: left;
	height: 100%;

	p {
		width: 100%;
	}

	.buttonCenter {
		text-align: center;
	}

	.image-section {
		position: relative;
		border-radius: 8px 8px 0px 0px;

		.modal-btn {
			border: none;
			background-color: transparent;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			height: 100%;
			z-index: 1;
			cursor: pointer;
		}

		img {
			width: 100%;
		}
	}

	.txt-section {
		padding: 25px;

		.title-bar {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.title {
				font: normal normal bold 1.3vw/4vw Nexa-bold;
			}

			.location {
				font: normal normal normal 1vw/4vw Nexa-light;
			}
		}

		.desc {
			font: normal normal normal 1.5vw/3vw Nexa-light;
		}
	}

	@media screen and ${breakpoints.device.laptopL} {
	
	.txt-section {
		padding: 25px;

		.title-bar {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.title {
				font: normal normal bold 1.2vw/4vw Nexa-bold;
			}

			.location {
				font: normal normal normal 1vw/4vw Nexa-light;
			}
		}

		.desc {
			font: normal normal normal 1.2vw/3vw Nexa-light;
		}
	}

	
	
	}

	@media screen and ${breakpoints.device.mobileL} {
		.txt-section {
			.title-bar {
				flex-direction: column;
				text-align: center;

				.title {
					font-size: 6vw;
					line-height: 1.5;
				}

				.location {
					font-size: 5vw;
					line-height: 2.5;
				}
			}

			.desc {
				font-size: 4vw;
				line-height: 2;
				text-align: center;
			}
		}
	}
`;

const EventCard = ({
	data,
	isModalOpen,
	setIsModalOpen,
	setVidCode,
	buyButton,
	learnMoreButton,
}) => {
	return (
		<motion.div
			initial={{ opacity: 0, x: -100, y: -50 }}
			whileInView={{
				opacity: 1,
				x: 0,
				y: 0,
			}}
			viewport={{ once: true }}
			transition={{
				type: "tween",
				duration: 0.85,
			}}>
			<StyledDiv>
				<div className="image-section">
					<button
						className="modal-btn"
						onClick={() => {
							setIsModalOpen(
								!isModalOpen
							);
							setVidCode(
								data.vidCode
							);
						}}
					/>
					<img src={data.image} alt="" />
				</div>
				<div className="txt-section">
					<div className="title-bar">
						<div className="title">
							{data.title}
						</div>
						<div className="location">
							<MdLocationOn className="elite" />
							{data.location}
						</div>
					</div>
					<p className="desc">{data.desc}</p>
					<div className="buttonCenter">
						{buyButton ? (
							<a
								href={buyButton}
								target="_blank"
								rel="noreferrer">
								<button className="section-btn">
									Buy
									Tickets
								</button>
							</a>
						) : learnMoreButton ? (
							<a
								href={learnMoreButton}
								target="_blank"
								rel="noreferrer">
								<button className="section-btn">
									Learn More
								</button>
							</a>
						) : (
							<></>
						)}
					</div>
				</div>
			</StyledDiv>
		</motion.div>
	);
};

export default EventCard;
